import * as React from 'react';
import { evolve, intersperse, mergeRight, test, values } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { connect } from 'react-redux';

import { Item, Container } from '../../../components/Grid/index';
import InputHandler from '../../../components/InputHandler';
import colors from '../../../config/theme/colors';

import { getJWTData } from '../../../utility/jwtAuthTools';
import { exists, validateSsn } from 'neuro-utils';

import CreationForm from './CreationForm';
import { actions } from '../../../store/session';
import generateAdvancedSearchString from './advancedSearchStringBuilder';
import { a2String } from '../../../utility/string';
import { makeLog } from '../../../utility/logger';
import { ws_manager, type RootState } from '../../../store';
import { createPatient } from 'Store/patient/apiFetchers';
import { IPatientDataForm } from 'Store/patient/util';
import ExtendedSearch, { IExtendedSearch } from './ExtendedSearch';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import NewSearchResults from '../components/SearchResults';
import {
  extendedSearchFields,
  TESDiagnosisOptions,
  TESSearchFieldNames,
  TMedicationType,
  TTreatmentType,
} from '../components/SearchFields';
import SearchSpinner from '../components/SearchSpinner';
import { actions as searchActions } from 'Store/patientsearch';
import { clearAllPatientDataAndSettings } from 'Store/combinedActions';
import { jwtModify, patientSearch, patientSelect } from '../fetchers';
import { StyledHeader } from '../components';
import { isEmpty } from 'Utility/ramdaReplacement';

const StyledSearchInfo = styled('div')({});

// Validate some part of the creation data, such as names and ssn
const validateForm = (newForm: IOwnState['newForm']): { [key: string]: boolean | string | null } => {
  const format = {
    firstNames: test(/([\w]+)/),
    lastNames: test(/([\w]+)/),
  };
  return evolve(format, newForm);
};

/** Delay in milliseconds before showing search results. */
const searchResultsDelay = 200;

type Props = IOwnProps & IStateFromProps & IDispatchFromProps;

const defaultSearchValues: IExtendedSearch['searchFields'] = {
  gender: 'noCropping' as const,
  age: 'noCropping',
  includeDeceased: 'no',
  diagnosis: 'noCropping',
  diagnosisDate: 'noCropping',
  typeOfDisease: 'noCropping', // MS
  medication: 'noCropping',
  substance: 'noCropping',
  // MS
  relapseDuringLast24Months: 'noCropping',
  relapseDuringLast12Months: 'noCropping',
  numberOfRelapses: 'noCropping',
  edss: 'noCropping',
  edssIncreasedDuringLast24Months: 'noCropping',
  edssIncreasedDuringLast12Months: 'noCropping',
  spmsCriteria: 'noCropping',
  patientAgeOnDg: 'noCropping',
  // Parkinson
  currentLEDD: 'noCropping',
  dbsTreatment: 'noCropping',
  thalamotomyOrHIFU: 'noCropping',
  currentHoehnYahr: 'noCropping',
  // S & R
  professionalDriving: 'noCropping',
  treatment: 'noCropping',
  latestBMI: 'noCropping',
};

const newFormDefaultData = {
  firstNames: '',
  lastNames: '',
  ssn: '',
  ssnLocalization: 'FIN', // Use fi localization format for now
  address: null,
  addressDetail: null,
  city: null,
  zipCode: null,
  country: null,
};

class PatientSearchAndCreation extends React.Component<Props, IOwnState> {
  mounted: boolean;
  timeout: NodeJS.Timeout | null;
  constructor(props: Props) {
    super(props);
    this.mounted = true;
    this.timeout = null;
    this.state = {
      searchLoading: false,
      searchText: '',
      wildcard: 'both',
      selected: '',
      newForm: {
        ...newFormDefaultData,
        firstNames: !this.props.session.data?.patientid ? this.props.session.data?.patientfirstnames || '' : '',
        lastNames: !this.props.session.data?.patientid ? this.props.session.data?.patientlastnames || '' : '',
        ssn: !this.props.session.data?.patientid ? this.props.session.data?.patientssn || '' : '',
      },
      validated: {},
      goToVisitReason: false,
      org: getJWTData()?.orgid || '',
      ssnValid: true,
      ssnInvalidMessage: 'general.checkSsn',
      platform: undefined,
      searchFields: defaultSearchValues,
    };
  }

  componentWillUnmount = (): void => {
    this.mounted = false;
  };

  public resetState = (): void => {
    this.setState({
      selected: '',
      newForm: newFormDefaultData,
      validated: {},
      goToVisitReason: false,
      ssnValid: true,
    });
  };

  public checkSsn = (ssn: string): void => {
    this.timeout && clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // Check if ssn exists -> if it does, display a popper saying that the ssn must be checked
      patientSearch(this.state.org, ssn).then((patients) => {
        const numberOfPatients = patients?.length || 0;
        if (numberOfPatients > 0) {
          this.setState({
            ssnInvalidMessage: 'general.ssnDuplicate',
            ssnValid: false,
          });
        } else {
          this.setState({
            ssnValid: true,
          });
        }
      });
    }, 1000);
  };

  public onChangeSearchText = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name] as string;

    if ((value.trim().match(/ /g) || []).length > 1) {
      this.setState({ validated: { ...this.state.validated, searchText: false } });
    } else {
      this.setState({ validated: { ...this.state.validated, searchText: true } });
    }
    this.setState({ searchText: value });
  };

  public onSelectPatient = (id: string) => (): void => {
    this.setState({ selected: id });
  };

  public onChangeNewForm = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0] as keyof IOwnState['newForm'];
    const value = values[name] as string;
    if (name === 'ssn') {
      if (validateSsn(value) === false) {
        this.setState({
          ssnInvalidMessage: 'general.checkSsn',
          ssnValid: false,
        });
      } else {
        this.setState({
          ssnValid: true,
        });
        this.checkSsn(value);
      }
    }

    this.setState({ newForm: mergeRight(this.state.newForm, { [name]: value }) });
  };

  public search = (): void => {
    if (this.state.searchText.length > 0) {
      this.setState({ searchLoading: true });

      this.props.doPatientSearch(this.state.searchText, this.state.wildcard).then(() => {
        // Artificial timeout to let spinner spin
        setTimeout(() => {
          this.setState({ searchLoading: false });
        }, searchResultsDelay);
      });
    }
  };

  public extendedSearch = (): void => {
    // to indicate to user that the search is executed, briefly hide results i.e. "flash" the area
    this.setState({ searchLoading: true });
    const url = [];

    const searchFields = this.state.searchFields;
    searchFields.gender && searchFields.gender !== 'noCropping' && url.push(`gender=${searchFields.gender}`);

    searchFields.includeDeceased !== 'yes' && url.push('excludeDeceased=true');

    searchFields.age &&
      searchFields.age !== 'noCropping' &&
      searchFields.age.minAge &&
      url.push('minAge=' + searchFields.age.minAge);
    searchFields.age &&
      searchFields.age !== 'noCropping' &&
      searchFields.age.maxAge &&
      url.push('maxAge=' + searchFields.age.maxAge);

    url.push(
      'search=' +
        encodeURIComponent(
          generateAdvancedSearchString(this.extendedSearchFields, {
            platform: this.state.platform,
            ...this.state.searchFields,
          }) ?? '',
        ),
    );

    if (!(url.length === 1 && url[0] === 'search=')) {
      this.props.doPatientExtendedSearch(a2String(intersperse('&', url))).then(() => {
        // Artificial timeout to let spinner spin
        setTimeout(() => {
          this.setState({ searchLoading: false });
        }, searchResultsDelay);
      });
    }
  };

  public submitNewPatient = (): void => {
    if (values(validateForm(this.state.newForm)).includes(false)) {
      // Validation fail
      this.setState({ validated: validateForm(this.state.newForm) });
    } else {
      this.setState({ validated: validateForm(this.state.newForm) });
      createPatient(this.state.newForm)
        .then((res: Response | null) => {
          if (res) {
            this.setState({ newForm: newFormDefaultData }); // Empty form
            this.fetchNewJWT(res);
          }
        })
        .catch((err: Error) => makeLog('Error', err));
    }
  };

  // Function to help with updating the diagnosis date field of extended search
  public updateExtendedSearchDiagnosisDate = (
    searchFields: IExtendedSearch['searchFields'],
    value: PartialDate,
    field: 'diagnosisMinDate' | 'diagnosisMaxDate',
  ): { diagnosisMinDate: PartialDate | null; diagnosisMaxDate: PartialDate | null } => {
    if (field === 'diagnosisMinDate') {
      return {
        diagnosisMinDate: value,
        diagnosisMaxDate:
          searchFields.diagnosisDate === 'noCropping' ? null : searchFields.diagnosisDate.diagnosisMaxDate,
      };
    } else {
      return {
        diagnosisMinDate:
          searchFields.diagnosisDate === 'noCropping' ? null : searchFields.diagnosisDate.diagnosisMinDate,
        diagnosisMaxDate: value,
      };
    }
  };

  public moveToVisitFromSearch = (id?: string): void => {
    const patientid = this.state.selected || id;
    if (patientid) {
      patientSelect(patientid).then((res) => res && this.fetchNewJWT(res));
    }
  };

  // Function to help with updating the min-max number field values of extended search
  public updateExtendedSearchMinMax = (
    searchFields: IExtendedSearch['searchFields'],
    value: string | number | Array<string | number>,
    parentField: keyof IExtendedSearch['searchFields'],
    fieldToUpdate: string,
    fieldIdentifier: string,
  ): { [key: string]: number | undefined } | 'noCropping' => {
    const parentFieldValue = searchFields[parentField];
    const minValue = parentFieldValue?.[`min${fieldIdentifier}` as keyof typeof parentFieldValue];
    const maxValue = parentFieldValue?.[`max${fieldIdentifier}` as keyof typeof parentFieldValue];
    switch (fieldToUpdate) {
      case `min${fieldIdentifier}`:
        return {
          [`min${fieldIdentifier}`]: typeof value === 'number' ? Number(value) : undefined,
          [`max${fieldIdentifier}`]: parentFieldValue === 'noCropping' ? undefined : maxValue,
        };
      case `max${fieldIdentifier}`:
        return {
          [`min${fieldIdentifier}`]: parentFieldValue === 'noCropping' ? undefined : minValue,
          [`max${fieldIdentifier}`]: typeof value === 'number' ? Number(value) : undefined,
        };
      default:
        return 'noCropping';
    }
  };

  // onChange function to pass as props to the ExtendedSearch component
  public onChangeExtendedSearchField = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name] as any;

    const setSearchFields = (
      field: string,
      value:
        | string
        | number
        | true
        | Array<number>
        | Record<string, number | undefined | PartialDate | null>
        | Array<TESDiagnosisOptions>
        | Array<TMedicationType>
        | Array<TTreatmentType>
        | Array<'RR' | 'SP' | 'PP' | 'notSpecified'>
        | Array<'expand' | 'lorscheider'>,
    ) => {
      this.setState({
        searchFields: {
          ...this.state.searchFields,
          [field]: !exists(value) || isEmpty(value) ? 'noCropping' : value,
        },
      });
    };

    switch (name) {
      case 'platform':
        this.setState({ platform: value, searchFields: defaultSearchValues });
        break;
      /**
       * Common
       */
      case 'age': {
        const ageField = extendedSearchFields()[this.state.platform || ''].find((field) => field.name === 'age');
        const min = ageField?.min;
        const max = ageField?.max;
        setSearchFields(
          'age',
          value === 'noCropping' || (value[0] === min && value[1] === max)
            ? 'noCropping'
            : { minAge: value[0], maxAge: value[1] },
        );
        break;
      }
      case 'diagnosis':
        if (
          this.state.platform === 'ms' &&
          (!Array.isArray(value) || !value.includes('ms')) &&
          this.state.searchFields.typeOfDisease !== 'noCropping'
        ) {
          this.setState({
            searchFields: {
              ...this.state.searchFields,
              diagnosis: value,
              typeOfDisease: 'noCropping',
            },
          });
        } else {
          setSearchFields('diagnosis', value);
        }
        break;
      case 'diagnosisDate':
        value === 'noCropping' && setSearchFields('diagnosisDate', 'noCropping');
        break;
      case 'diagnosisMinDate':
      case 'diagnosisMaxDate': {
        const name2 = name === 'diagnosisMinDate' ? 'diagnosisMaxDate' : 'diagnosisMinDate';
        if (
          value === null &&
          this.state.searchFields.diagnosisDate !== 'noCropping' &&
          this.state.searchFields.diagnosisDate[name2] === null
        ) {
          setSearchFields('diagnosisDate', 'noCropping');
        } else {
          setSearchFields(
            'diagnosisDate',
            this.updateExtendedSearchDiagnosisDate(this.state.searchFields, value, name),
          );
        }
        break;
      }
      case 'medication':
        if (Array.isArray(value) && value.length === 1 && typeof value[0] === 'object' && !value[0].medication)
          setSearchFields('medication', 'noCropping');
        else setSearchFields('medication', value);
        break;
      /**
       * MS
       */
      case 'relapseDuringLast24Months':
        if (value === 'no' && this.state.searchFields.relapseDuringLast12Months !== 'noCropping') {
          this.setState({
            searchFields: {
              ...this.state.searchFields,
              relapseDuringLast24Months: value,
              relapseDuringLast12Months: 'noCropping',
              numberOfRelapses: 'noCropping',
            },
          });
        } else {
          setSearchFields('relapseDuringLast24Months', value);
        }
        break;
      case 'relapseDuringLast12Months':
        if (value === 'no' && this.state.searchFields.numberOfRelapses !== 'noCropping') {
          this.setState({
            searchFields: {
              ...this.state.searchFields,
              relapseDuringLast12Months: 'no',
              numberOfRelapses: 'noCropping',
            },
          });
        } else {
          setSearchFields('relapseDuringLast12Months', value);
        }
        break;
      /**
       * Parkinson
       */
      case 'minLEDD':
      case 'maxLEDD':
        setSearchFields(
          'currentLEDD',
          this.updateExtendedSearchMinMax(this.state.searchFields, value, 'currentLEDD', name, 'LEDD'),
        );
        break;
      /**
       * S & R
       */
      case 'treatment':
        if (
          Array.isArray(value) &&
          value.length === 1 &&
          typeof value[0] === 'object' &&
          (!value[0].treatment || value[0].treatment === 'noCropping')
        )
          setSearchFields('treatment', 'noCropping');
        else setSearchFields('treatment', value);
        break;
      case 'latestBMI': {
        const bmiField = extendedSearchFields()[this.state.platform || ''].find((field) => field.name === 'latestBMI');
        const min = bmiField?.min;
        const max = bmiField?.max;
        setSearchFields(
          'latestBMI',
          value === 'noCropping' || (value[0] === min && value[1] === max)
            ? 'noCropping'
            : { minBMI: value[0], maxBMI: value[1] },
        );
        break;
      }
      /**
       * Default
       */
      default:
        setSearchFields(name, value);
    }
  };

  public fetchNewJWT = (res: Response): void => {
    if (res.status === 200) {
      res.text().then((text: string) => {
        jwtModify(text).then((success) => {
          if (success) {
            this.props.loadSession();
            this.props.clearAllPatientData();
          }
        });
      });
    }
  };

  private handleEnter = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && this.state.validated?.searchText !== false) {
      this.search();
    }
  };

  extendedSearchFields = {
    platform: ['platform'],
    profile: ['gender', 'age', 'includeDeceased'],
    diagnosis: ['diagnosis', 'diagnosisDate', /** MS */ 'typeOfDisease', /** MS */ 'patientAgeOnDg'],
    medication: ['medication'],
    substance: ['substance'],
    // MS
    relapse: ['relapseDuringLast24Months', 'relapseDuringLast12Months', 'numberOfRelapses'],
    neurologicalStatusAndEDSS: [
      'edss',
      'edssIncreasedDuringLast24Months',
      'edssIncreasedDuringLast12Months',
      'spmsCriteria',
    ],
    // Parkinson
    ledd: ['currentLEDD'],
    dbs: ['dbsTreatment'],
    thalamotomyOrHIFU: ['thalamotomyOrHIFU'],
    updrs_v: ['currentHoehnYahr'],
    // S & R
    treatment: ['treatment'],
    background: ['professionalDriving', 'latestBMI'],
  } as { [key: string]: TESSearchFieldNames[] };

  public render(): JSX.Element {
    const { searchResults } = this.props;
    const { searchText, newForm, validated } = this.state;
    const { patientid, patientfirstnames, patientlastnames, patientssn } = this.props.session.data || {};

    /** State relevant to ExtendedSearch (es) component. */
    const esState = {
      platform: this.state.platform,
      searchFields: this.state.searchFields,
      searchLoading: this.state.searchLoading,
    };

    const showRightSideContent = !!searchResults || (!!patientssn && !patientid);

    return this.props.extended ? (
      <ExtendedSearch
        state={esState}
        onChange={this.onChangeExtendedSearchField}
        extendedSearch={this.extendedSearch}
        showSearchResults={showRightSideContent}
        onClickResult={(...args) => {
          ws_manager.disconnect();
          this.moveToVisitFromSearch(...args);
        }}
      />
    ) : (
      <Container>
        <Item
          xs={6}
          style={{
            borderRight: `1px solid ${showRightSideContent ? colors.gray : 'transparent'}`,
          }}
          sx={{ paddingRight: { xs: '4rem', md: '20rem' } }}
          onKeyUp={this.handleEnter}
        >
          <StyledHeader>
            <FormattedMessage id="general.searchTitle" />
          </StyledHeader>
          <div style={{ marginBottom: '1.5rem' }}>
            <StyledSearchInfo>
              <FormattedMessage id="general.searchInfo" />
            </StyledSearchInfo>
          </div>
          <div style={{ marginBottom: '2.5rem' }}>
            <InputHandler
              type="TextField"
              editing={true}
              fullWidth={true}
              name="patientSearch"
              placeholder="general.searchPlaceholder"
              formData={{
                document: { patientSearch: searchText },
                onChange: this.onChangeSearchText,
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ActionButtonRounded
              width={6}
              height={4}
              fontSize={16}
              text="general.search"
              onClick={this.search}
              disabled={validated?.searchText === false}
              disabledTooltip={<FormattedMessage id="general.searchDisabledInfo" />}
              filled
            />
          </div>
        </Item>
        <Item xs={6} style={{ paddingLeft: '4rem' }}>
          {showRightSideContent &&
            (this.state.searchLoading ? (
              <SearchSpinner text="general.searching" />
            ) : (
              <React.Fragment>
                {searchResults && searchResults.length > 0 ? (
                  <NewSearchResults
                    searchResults={searchResults}
                    onClickResult={(...args) => {
                      ws_manager.disconnect();
                      this.moveToVisitFromSearch(...args);
                    }}
                  />
                ) : (
                  <React.Fragment>
                    <div style={{ marginBottom: '2.5rem' }}>
                      <CreationForm
                        form={newForm}
                        onChangeNewForm={this.onChangeNewForm}
                        validated={validated}
                        patient={
                          patientssn
                            ? {
                                ssn: patientssn,
                                lastNames: patientlastnames,
                                firstNames: patientfirstnames,
                              }
                            : undefined
                        }
                        ssnValid={this.state.ssnValid}
                        ssnInvalidMessage={this.state.ssnInvalidMessage}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <ActionButtonRounded
                        width={14}
                        height={4}
                        fontSize={16}
                        text="general.createPatient"
                        onClick={() => {
                          ws_manager.disconnect();
                          this.submitNewPatient();
                        }}
                        disabled={!this.state.ssnValid}
                        filled
                      />
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
        </Item>
      </Container>
    );
  }
}

export interface IOwnState extends IExtendedSearch {
  searchLoading: boolean;
  searchText: string;
  wildcard: 'start' | 'end' | 'both';
  selected: string;
  newForm: IPatientDataForm;
  validated: { [key: string]: boolean | string | null };
  goToVisitReason: boolean;
  org: string;
  ssnValid: boolean;
  ssnInvalidMessage: string;
}

interface IDispatchFromProps {
  loadSession: () => void;
  clearAllPatientData: () => void;
  doPatientSearch: (searchString: string, wildcard: string) => Promise<true>;
  doPatientExtendedSearch: (searchString: string) => Promise<true>;
  clearSearchResults: () => void;
}

interface IStateFromProps {
  session: ISessionStore;
  searchResults: (TNeuroPersonData | IExtendedSearchResults)[] | null;
  settings: ISettingsStore;
}

const mapDispatchToProps = (dispatch: any): IDispatchFromProps => ({
  loadSession: (): void => dispatch(actions.loadSession()),
  clearAllPatientData: (): void => dispatch(clearAllPatientDataAndSettings()),
  doPatientSearch: (searchString: string, wildcard: string): Promise<true> =>
    dispatch(searchActions.doPatientSearch(searchString, wildcard)),
  doPatientExtendedSearch: (searchString: string): Promise<true> =>
    dispatch(searchActions.doExtendedSearch(searchString)),
  clearSearchResults: (): void => dispatch(searchActions.clearSearchResults),
});

const mapStateToProps = (state: RootState): IStateFromProps => ({
  session: state.session || {},
  searchResults: state.patientsearch.searchResults,
  settings: state.settings,
});

interface IOwnProps {
  extended: boolean;
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearchAndCreation);
