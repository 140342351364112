import * as React from 'react';
import { useIntl } from 'react-intl';

import { Container, Item } from 'Components/Grid';
import TabContent from 'Components/TabContent';
//import ActionButtonRounded from 'Components/ActionButtonRounded';

import { StyledHeader } from '../components';
//import Announcements from './Announcements';
import Messages from './Messages';
import AnnouncementForm from './AnnouncementForm';

const MessageCentral = () => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [formOpen, setFormOpen] = React.useState<boolean>(false);

  return formOpen ? (
    <AnnouncementForm setFormOpen={setFormOpen} />
  ) : (
    <>
      <Container style={{ marginBottom: '2rem' }}>
        <Item xs={9}>
          <StyledHeader>{fm('messaging.title')}</StyledHeader>
          <div>{fm('messaging.messagingInfoText')}</div>
        </Item>
        {/* <Item xs={3}>
          <Container justifyContent={'flex-end'}>
            <Item>
              <ActionButtonRounded
                text="messaging.newAnnouncement"
                width={'auto'}
                height={4}
                fontSize={18}
                onClick={() => setFormOpen(true)}
              />
            </Item>
          </Container>
        </Item> */}
      </Container>
      <TabContent roundedTabs disableScaling hideTabsWhenSingle>
        {[
          { id: 'messages', title: fm('messaging.messagingPatients'), content: <Messages fm={fm} /> },
          // { id: 'announcements', title: fm('messaging.announcements'), content: <Announcements fm={fm} /> },
        ]}
      </TabContent>
    </>
  );
};

export default MessageCentral;
