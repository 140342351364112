import ActionButtonRounded from 'Components/ActionButtonRounded';
import FormRow from 'Components/FormRow';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';

const AnnouncementForm = ({ setFormOpen }: IAnnouncementForm) => {
  const [formData, setFormData] = React.useState<Record<string, any>>({});

  const onChange = (name: string) => (values: TOnChangeValues) => {
    const value = values?.[name];
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <FormRow title="messaging.messageDate">
        <InputHandler
          type="PartialDate"
          name="date"
          editing={true}
          formData={{ document: { date: formData?.['date'] }, onChange: onChange('date') }}
          dateDefault={'now'}
        />
      </FormRow>
      <FormRow title="messaging.messageTitle">
        <InputHandler
          type="TextField"
          name="title"
          editing={true}
          formData={{ document: { title: formData?.['title'] }, onChange: onChange('title') }}
          placeholder={'messaging.messageTitle'}
        />
      </FormRow>
      <FormRow title="messaging.messageBody">
        <InputHandler
          type="TextArea"
          name="body"
          editing={true}
          formData={{ document: { body: formData?.['body'] }, onChange: onChange('body') }}
          placeholder={'messaging.messageBody'}
        />
      </FormRow>
      <Container>
        <Item xs={true}>
          <ActionButtonRounded
            text="general.cancel"
            onClick={() => setFormOpen(false)}
            fontSize={16}
            width={18}
            height={4}
          />
        </Item>
        <Item xs={6}>
          <Container justifyContent={'flex-end'}>
            <Item>
              <ActionButtonRounded
                text="messaging.send"
                onClick={() => setFormOpen(false)}
                fontSize={16}
                width={18}
                height={4}
                filled
              />
            </Item>
          </Container>
        </Item>
      </Container>
    </>
  );
};

interface IAnnouncementForm {
  setFormOpen: (open: boolean) => void;
}

export default AnnouncementForm;
