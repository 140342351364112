import { styled, Theme } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { useAppSelector } from 'Store/index';
import colors from '../../../../config/theme/colors';
import { formatPartialDate, partialDateFromDate } from 'neuro-utils';
import Icon from 'Components/_NewElements/Icon';
import { useIntl } from 'react-intl';
import { IMessageListItem } from 'Store/messaging/interfaces';
import { messageFetchBuilder } from 'Store/messaging/fetchers/messages';
import ThemedDialog from 'Components/_NewElements/Dialog';
import InputHandler from 'Components/InputHandler';
import ConfirmationDialog from 'Components/ConfirmationDialog';

const StyledDividerArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'compact',
})(({ theme, compact }: { theme?: Theme; compact: boolean }) => ({
  margin: compact ? '1.5rem auto 1rem auto' : '3rem auto 2rem auto',
  paddingTop: compact ? '0.5rem ' : '1rem',
  borderTop: '1px solid ' + colors.mediumGray + 'aa',
  color: theme?.palette.grey[500],
  textAlign: 'center',

  '&:first-of-type': {
    marginTop: '0rem !important',
    paddingTop: 0,
  },
}));

const StyledMessagesContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'compact',
})(({ theme, compact }: { theme?: Theme; compact: boolean }) => ({
  backgroundColor: theme?.palette.grey[100] + '99',
  borderRadius: '0.4rem',
  padding: compact ? '1rem' : '2rem 1rem',
  '& > div:not(:last-child)': {
    marginBottom: compact ? '1rem' : '2rem',
  },
  '& > div:first-of-type': {
    marginTop: '0rem !important',
  },

  //minHeight: '30rem',
  maxHeight: compact ? '100%' : '40rem',
  overflowY: 'auto',
  overflowX: 'auto',
}));

const StyledSingleMessageContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isPatient',
})(({ isPatient }: { isPatient: boolean }) => ({
  width: '100%',
  cursor: 'pointer',
  '.controls': {
    display: 'none',
  },
  ':hover': {
    //backgroundColor: !isPatient ? theme?.palette.grey[100] : 'inherit',
    '.controls': {
      display: !isPatient ? 'flex' : 'none',
    },
  },
}));

const StyledMessageControlsArea = styled(Item)(({ theme }) => ({
  '& > div': {
    cursor: 'pointer',
    padding: '0.5rem',
    ':hover': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '50rem',
    },
  },
}));

const StyledSingleMessageBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPatient',
})(({ theme, isPatient }: { theme?: Theme; isPatient: boolean }) => ({
  backgroundColor: (isPatient ? theme?.customPalette.myms.primary.main : theme?.palette.primary.main) + '33',
  borderRadius: '0.8rem',
  padding: '0.7rem 1.3rem',
  width: 'fit-content',
  fontWeight: 400,
  borderTopRightRadius: isPatient ? '0.8rem' : 0,
  borderTopLeftRadius: isPatient ? 0 : '0.8rem',
}));

const StyledTextDeleted = styled('span')(({ theme }) => ({
  color: theme.palette.grey[700],
  fontStyle: 'italic',
}));

const StyledUserSmall = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPatient',
})(({ theme, isPatient }: { theme?: Theme; isPatient: boolean }) => ({
  fontSize: '1.2rem',
  textAlign: isPatient ? 'start' : 'end',
  margin: isPatient ? '0 0 0 4.5rem !important' : '0 4.5rem 0 0 !important',
  color: theme?.palette.grey[700],
}));

const ChatView = ({ messages, thread, updateMessages, minimized = false, ended }: IChatView) => {
  const sortedMessages = messages.toSorted((msg1, msg2) => msg1.timestamp - msg2.timestamp);

  const containerRef = React.useRef<null | HTMLDivElement>(null);
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [minimized]);

  const users: TCompactVaultUser[] = useAppSelector((s) => s.session.orgUsers) || [];

  const hcpUserName = (id: string) => {
    const user = users.find((urs) => urs.userId === id);
    return user ? `${user?.lastNames} ${user?.firstNames}` : fm('general.unknown');
  };

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const compact = minimized === true;

  const [editMessageData, setEditMessageData] = React.useState<{ id: string; messageString: string } | null>(null);
  const editMessage = (newMessageString: string, messageId: string) => {
    messageFetchBuilder<'update'>({
      name: 'update',
      queryParams: [thread.patientId, thread.id, messageId],
      body: newMessageString,
    }).then((res) => {
      if (res) {
        setEditMessageData(null);
        updateMessages();
      }
    });
  };
  const [deleteMessageId, setDeleteMessageId] = React.useState<string | null>(null);
  const deleteMessage = (messageId: string) => {
    messageFetchBuilder<'delete'>({ name: 'delete', queryParams: [thread.patientId, messageId] }).then((res) => {
      if (res) {
        setDeleteMessageId(null);
        updateMessages();
      }
    });
  };

  const endedDate = ended && new Date(ended);
  const getTimeString = (timeDate: Date) => {
    return `${timeDate.getHours()}:${`${
      timeDate.getMinutes().toString().length === 1 ? 0 : ''
    }${timeDate.getMinutes()}`}`;
  };
  return (
    <>
      <StyledMessagesContainer ref={containerRef} compact={compact}>
        {sortedMessages.map((msg, i) => {
          const isPatient = msg.senderApplication === 'myneuro';

          const timedate = new Date(msg.timestamp);

          const getDay = (timestamp: number) => {
            return new Date(timestamp).getDay();
          };

          const dayHasChanged = sortedMessages[i - 1]?.timestamp
            ? getDay(sortedMessages[i - 1].timestamp) !== getDay(msg.timestamp)
            : !sortedMessages[i - 1]?.timestamp;

          const messageDeleted = msg.deletionTimestamp;

          return (
            <React.Fragment key={msg.id}>
              {dayHasChanged && (
                <StyledDividerArea compact={compact}>
                  {formatPartialDate(partialDateFromDate(timedate))}
                </StyledDividerArea>
              )}

              {/** Todo show name for patient / delegate as well */}
              {isPatient && <StyledUserSmall isPatient={true}>{'Potilas'}</StyledUserSmall>}
              {!isPatient && <StyledUserSmall isPatient={false}>{hcpUserName(msg.senderId)}</StyledUserSmall>}

              <StyledSingleMessageContainer
                key={msg.id}
                direction={isPatient ? 'row' : 'row-reverse'}
                alignItems={'center'}
                isPatient={isPatient}
              >
                <Item xs="auto" style={{ padding: '0 1rem' }}>
                  <Icon icon={isPatient ? 'patient' : 'org'} color={isPatient ? colors.myms.primary : undefined} />
                </Item>
                <Item xs={'auto'} style={{ maxWidth: '70%' }}>
                  <StyledSingleMessageBody isPatient={isPatient}>
                    {messageDeleted ? (
                      <StyledTextDeleted>{fm('messaging.messageDeleted')}</StyledTextDeleted>
                    ) : (
                      <div style={{ whiteSpace: 'pre-wrap' }}>{msg.body}</div>
                    )}
                  </StyledSingleMessageBody>
                </Item>
                <Item style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                  {!messageDeleted && !ended && (
                    <StyledMessageControlsArea className="controls">
                      <div style={{ marginRight: '0.5rem' }} onClick={() => setDeleteMessageId(msg.id)}>
                        <Icon icon="close" color={'errorDefault'} />
                      </div>
                      <div onClick={() => setEditMessageData({ id: msg.id, messageString: msg.body })}>
                        <Icon icon="edit" iconSize={2} />
                      </div>
                    </StyledMessageControlsArea>
                  )}
                  {/** Todo show edit timestamp */}
                  <div style={{ fontSize: '1.4rem', padding: '0 1rem', color: colors.darkGray }}>{`${getTimeString(
                    timedate,
                  )}`}</div>
                </Item>
                <Item xs={2} />
              </StyledSingleMessageContainer>
            </React.Fragment>
          );
        })}
        {endedDate && (
          <div style={{ color: colors.gray, textAlign: 'center', marginBottom: '0' }}>
            {fm('messaging.discussionMarkedAsCompleted')}
            {`: ${formatPartialDate(partialDateFromDate(endedDate))} ${getTimeString(endedDate)}`}
          </div>
        )}
        <span ref={messagesEndRef} />
      </StyledMessagesContainer>
      <ThemedDialog
        open={!!(editMessageData || editMessageData === '')}
        dialogProps={{ maxWidth: 'xs' }}
        dialogActions={[
          { text: 'general.cancel', onClick: () => setEditMessageData(null), border: false },
          {
            text: 'general.save',
            onClick: () => editMessageData && editMessage(editMessageData.messageString, editMessageData.id),
          },
        ]}
        title={<div>{fm('messaging.enterNewText')}</div>}
      >
        <InputHandler
          type="TextArea"
          editing
          name={'editString'}
          formData={{
            onChange: (values) => {
              const newString = values['editString'];
              if (typeof newString === 'string') {
                editMessageData && setEditMessageData({ ...editMessageData, messageString: newString });
              }
            },
            document: { editString: editMessageData?.messageString },
          }}
          fullWidth
          rows={4}
          placeholder={fm('messaging.enterNewText')}
        />
      </ThemedDialog>
      <ConfirmationDialog
        open={!!deleteMessageId}
        text={fm('messaging.deleteConfirmation')}
        cancel={{ callback: () => setDeleteMessageId(null) }}
        confirm={{ callback: () => deleteMessageId && deleteMessage(deleteMessageId) }}
      />
    </>
  );
};

interface IChatView {
  messages: IMessageListItem[];
  thread: IThreadListItem;
  updateMessages: () => void;
  minimized?: boolean;
  ended?: number | null;
}

export default ChatView;
