import { fetchWithOptions } from 'Utility/fetch';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';

/**
 * Patient search by ssn for the purpose of some checks
 * @param {string} org - Org id
 * @param {string} ssn - Patient ssn
 * @returns {Promise<TNeuroPersonData[]> | null} Matched patients
 */
export const patientSearch = async (org: string, ssn: string): Promise<TNeuroPersonData[] | null> => {
  return await fetchWithOptions(
    `/api/patient/v2/search/${org}?search=${ssn}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then(
      (res: Response) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw { status: res.status, fullResponse: res };
        }
      },
      (error: Error) => {
        throw error;
      },
    )
    .catch((err: Error) => {
      makeLog('Error', { name: 'Patient search', message: 'Patient search by ssn failed' }, err);
      return null;
    });
};

/**
 * Patient selection
 * @param {string} patientid - Patient id
 * @returns {Promise<Response | null>} Response
 */
export const patientSelect = async (patientid: string): Promise<Response | null> => {
  return fetchWithOptions(
    `/api/patient/v2/select/`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify({ id: patientid }) },
  )
    .then(
      (res: Response) => {
        if (res.status === 200) {
          return res;
        } else {
          throw { status: res.status, fullResponse: res };
        }
      },
      (error: Error) => {
        throw error;
      },
    )
    .catch((err: Error) => {
      makeLog('Error', { name: 'Patient selection', message: 'Patient selection failed' }, err);
      return null;
    });
};

/**
 * JWT modification
 * @param {string} resText - Response text
 * @returns {Promise<boolean>} Success
 */
export const jwtModify = async (resText: string): Promise<boolean> => {
  return fetchWithOptions(`/auth/modify`, { neurojwt: parseJWTFromCookie() }, { method: 'PATCH', body: resText })
    .then(
      (res: Response) => {
        if (res.status === 200) {
          return true;
        } else {
          throw { status: res.status, fullResponse: res };
        }
      },
      (error: Error) => {
        throw error;
      },
    )
    .catch((err: Error) => {
      makeLog('Error', { name: 'JWT modify', message: 'JWT modification failed' }, err);
      return false;
    });
};
