import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import TextArea from 'Components/InputHandler/components/TextArea';
import Paper from 'Components/Paper';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { HeaderTimeDisplay } from 'Routes/_MainLander/MessageCentral/components';
import ChatView from 'Routes/_MainLander/MessageCentral/components/ChatView';
import { useAppDispatch, useAppSelector } from 'Store/index';
import colors from '../../config/theme/colors';
import { actions as sessionActions } from 'Store/session';
import { actions as messagingActions } from 'Store/messaging';
import Icon from 'Components/_NewElements/Icon';
import NewMessage from './NewMessage';
import { IMessageListItem, IThreadListItem } from 'Store/messaging/interfaces';
import CircularProgressLocal from 'Components/CircularProgress';
import { messageFetchBuilder } from 'Store/messaging/fetchers/messages';
import { Menu, MenuItem, styled } from '@mui/material';
import { threadFetchBuilder } from 'Store/messaging/fetchers/threads';
import ToolTip from 'Components/ToolTip';
import ConfirmationDialog from 'Components/ConfirmationDialog';

const StyledUserCircle = styled('div')({
  backgroundColor: colors.darkGray,
  borderRadius: '50rem',
  width: '3rem',
  height: '3rem',
  color: colors.white,
  letterSpacing: '0.1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ThreadOpen = ({ thread }: IThreadOpen) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const minimized = useAppSelector((s) => s.session.messagingSessionSettings.minimized);

  const [messages, setMessages] = React.useState<IMessageListItem[]>([]);

  const updateMessages = () => {
    messageFetchBuilder<'list'>({ name: 'list', queryParams: [thread.patientId, thread.id] }).then((res) => {
      if (Array.isArray(res)) {
        setMessages(res);
      }
    });
  };

  React.useEffect(() => {
    // Fetch messages when opening the thread
    updateMessages();
  }, []);

  const [newMessageBody, setNewMessageBody] = React.useState<string | null>(null);

  const sendNewMessage = () => {
    newMessageBody &&
      messagingActions.newMessageToThread(newMessageBody, thread.patientId, thread.id).then(() => {
        updateMessages();
        setNewMessageBody(null);
      });
  };

  const ended = thread.completionTimestamp;
  return (
    <>
      {messages.length > 0 && (
        <ChatView
          messages={messages}
          thread={thread}
          minimized={minimized}
          updateMessages={updateMessages}
          ended={ended}
        />
      )}
      {messages.length === 0 && <div></div>}
      {!ended && (
        <Container style={{ marginTop: '1rem' }}>
          <Item xs={true}>
            <TextArea
              editing
              type="TextArea"
              name="reply"
              placeholder={fm('messaging.replyPlaceholder')}
              rows={2}
              fullWidth
              id="messagingReplyBox"
              autoHeight
              value={newMessageBody || ''}
              onChange={(values) => {
                const newMessageString = values['reply'];
                if (typeof newMessageString === 'string') {
                  setNewMessageBody(newMessageString);
                }
              }}
            />
          </Item>
          <Item xs={3}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ActionButtonRounded
                width="auto"
                filled
                height={3}
                fontSize={15}
                text={'messaging.send'}
                disabled={!newMessageBody}
                onClick={() => sendNewMessage()}
              />
            </div>
          </Item>
        </Container>
      )}
    </>
  );
};

interface IThreadOpen {
  thread: IThreadListItem;
}

const ThreadHeader = ({ thr, fm }: { thr: IThreadListItem; fm: (s: string) => string }) => {
  const lastDate = thr.latestMessage?.timestamp;

  const thisUserId: string = useAppSelector((s) => s.session.data?.useruuid) || '';
  const dispatch = useAppDispatch();

  const thisUserIsTheHandler = thr.assignedFor?.id === thisUserId;

  const [anchor, setAnchor] = React.useState<null | Element>(null);
  const toggleMenu = (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    setAnchor(!anchor ? e.currentTarget : null);
  };

  const assignUserToggle = async (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    if (thisUserIsTheHandler) {
      await threadFetchBuilder({ name: 'unassign', queryParams: [thr.id] });
    } else {
      await threadFetchBuilder({ name: 'assign', queryParams: [thr.id, thisUserId] });
    }
    dispatch(messagingActions.loadThreadList(true));
    toggleMenu(e);
  };

  const completeDiscussionToggle = async (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    if (thr.completionTimestamp) {
      await threadFetchBuilder({ name: 'uncomplete', queryParams: [thr.id] });
    } else {
      await threadFetchBuilder({ name: 'complete', queryParams: [thr.id] });
    }
    dispatch(messagingActions.loadThreadList(true));
    toggleMenu(e);
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);
  const deleteDiscussion = async (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    setDeleteConfirmationOpen(true);
    toggleMenu(e);
  };

  return (
    <>
      <Container>
        <Item xs={true}>
          <div style={{ fontSize: '1.8rem' }}>{thr.topicTitle || fm('messaging.noTitle')}</div>
          {lastDate ? (
            <HeaderTimeDisplay date={lastDate} />
          ) : (
            <div style={{ fontSize: '1.4rem' }}>{fm('messaging.noDate')}</div>
          )}
        </Item>
        {thr?.assignedFor && (
          <Item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1rem' }}>
            <ToolTip
              hover
              title={
                <>
                  {fm('messaging.handler')}: {`${thr.assignedFor.lastNames} ${thr.assignedFor.firstNames}`}
                </>
              }
              content={
                <StyledUserCircle>
                  <h2 style={{ margin: 0, padding: 0, fontSize: '1.6rem' }}>
                    {thr.assignedFor.lastNames.charAt(0)}
                    {thr.assignedFor.firstNames.charAt(0)}
                  </h2>
                </StyledUserCircle>
              }
            />
          </Item>
        )}
        <Item style={{ display: 'flex', alignItems: 'center' }} onClick={toggleMenu}>
          <Icon icon="moreVert" />
        </Item>
      </Container>
      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={toggleMenu}>
        <MenuItem onClick={assignUserToggle}>
          {fm(thisUserIsTheHandler ? 'messaging.unassignMeAsHandler' : 'messaging.assignMeAsHandler')}
        </MenuItem>
        <MenuItem onClick={completeDiscussionToggle}>
          {fm(thr.completionTimestamp ? 'messaging.markUncomplete' : 'messaging.markComplete')}
        </MenuItem>
        <MenuItem onClick={deleteDiscussion}>{fm('messaging.deleteDiscussion')}</MenuItem>
      </Menu>
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        text={fm('messaging.deleteDiscussionConfirmation')}
        cancel={{
          callback: (e) => {
            e.stopPropagation();
            setDeleteConfirmationOpen(false);
          },
        }}
        confirm={{
          callback: async (e) => {
            e.stopPropagation();
            await threadFetchBuilder({ name: 'delete', queryParams: [thr.id] });
            dispatch(messagingActions.loadThreadList(true));
            setDeleteConfirmationOpen(false);
          },
        }}
      />
    </>
  );
};

const MessasingToPatient = () => {
  const [openThreadId, setOpenThreadId] = React.useState<string | null>(null);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const dispatch = useAppDispatch();
  const minimized = useAppSelector((s) => s.session.messagingSessionSettings.minimized);
  const position = useAppSelector((s) => s.session.messagingSessionSettings.position);
  const newMessage = useAppSelector((s) => s.session.messagingSessionSettings.newMessage);

  // Bit of a trick to unrender these tooltips when position or size is changed
  const [minimizeTooltip, setMinimizeTooltip] = React.useState<string | null>(
    fm(minimized ? 'messaging.maximize' : 'messaging.minimize'),
  );
  const [positionTooltip, setPositionTooltip] = React.useState<string | null>(
    fm(position === 'right' ? 'messaging.moveLeft' : 'messaging.moveRight'),
  );

  const setMinimize = () => {
    dispatch(sessionActions.setMessagesSessionSettings({ minimized: !minimized }));
    setMinimizeTooltip(null);
    setTimeout(() => {
      setMinimizeTooltip(fm(!minimized ? 'messaging.maximize' : 'messaging.minimize'));
    }, 100);
  };
  const setPosition = () => {
    const currentPosition = position || 'left';
    dispatch(sessionActions.setMessagesSessionSettings({ position: currentPosition === 'right' ? 'left' : 'right' }));
    setPositionTooltip(null);
    setTimeout(() => {
      setPositionTooltip(fm(currentPosition === 'left' ? 'messaging.moveLeft' : 'messaging.moveRight'));
    }, 100);
  };

  const closeMessaging = () => dispatch(sessionActions.setMessagesSessionSettings({ open: false }));

  const newDiscussionChange = (newMessage: Record<'title' | 'body', string> | undefined) => {
    dispatch(sessionActions.setMessagesSessionSettings({ newMessage }));
  };

  const postNewDiscussion = () => {
    if (newMessage?.title && newMessage?.body) {
      setLoadingThreads(true);
      dispatch(messagingActions.createNewDiscussionWithPatient(newMessage?.title, newMessage?.body)).then(() => {
        setLoadingThreads(false);
      });
      newDiscussionChange(undefined);
    }
  };

  const [loadingThreads, setLoadingThreads] = React.useState<boolean>(true);
  React.useEffect(() => {
    dispatch(messagingActions.loadThreadList(true)).then(() => {
      setTimeout(() => {
        setLoadingThreads(false);
      }, 500);
    });
  }, []);

  const threads = useAppSelector((s) => s.messaging.threads) || [];
  const activePatientThreads = threads
    .filter((thr) => !thr.deletionTimestamp)
    .filter((thr) => !thr.completionTimestamp)
    .toSorted((a, b) => {
      const aDate = a.latestMessage?.timestamp || Date.now();
      const bDate = b.latestMessage?.timestamp || Date.now();
      return bDate - aDate;
    });

  const endedPatientThreads = threads
    .filter((thr) => !thr.deletionTimestamp)
    .filter((thr) => thr.completionTimestamp)
    .toSorted((a, b) => {
      const aDate = a.latestMessage?.timestamp || Date.now();
      const bDate = b.latestMessage?.timestamp || Date.now();
      return bDate - aDate;
    });

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: minimized ? '30rem' : 'calc(100% - 2rem)',
        position: 'fixed',
        bottom: '1rem',
        left: !position || position === 'left' ? '1rem' : undefined,
        right: position === 'right' ? '1rem' : undefined,
        width: minimized ? '65rem' : '65rem',
        zIndex: 50,
      }}
    >
      <Paper
        padding={0}
        elevation={15}
        square
        style={{ height: '100%', overflowY: 'auto', padding: '0 1.5rem 1.5rem 1.5rem' }}
      >
        <div style={{ padding: '1rem 0 0 0', height: '3.5rem', position: 'sticky', top: 0, backgroundColor: 'white' }}>
          <Container>
            <Item xs={true}>
              <div style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
                {fm(newMessage ? 'messaging.newDiscussion' : 'messaging.messagingPatient')}
              </div>
            </Item>

            {/* <Item style={{ display: 'flex', alignItems: 'center', marginRight: '2rem' }}>
              <div style={{ marginRight: '0.3rem' }}>{fm('messaging.showClosedShort')}</div>
              <Checkbox style={{ padding: 0 }} />
            </Item> */}
            <Item xs={'auto'} style={{ marginRight: '1rem' }}>
              <ActionButtonRounded
                text={<>{<Icon icon={position === 'right' ? 'leftMoveArrow' : 'rightMoveArrow'} size={2} />}</>}
                height={2.5}
                width={'auto'}
                fontSize={14}
                onClick={() => setPosition()}
                customButtonStyles={{ padding: '0 0.5rem' }}
                toolTip={positionTooltip ? <>{positionTooltip}</> : undefined}
              />
            </Item>
            <Item xs={'auto'} style={{ marginRight: '1rem' }}>
              <ActionButtonRounded
                text={<>{<Icon icon={minimized ? 'maximize' : 'minimize'} size={1.8} />}</>}
                height={2.5}
                width={'auto'}
                fontSize={14}
                onClick={() => setMinimize()}
                customButtonStyles={{ padding: '0 0.5rem' }}
                toolTip={minimizeTooltip ? <>{minimizeTooltip}</> : undefined}
              />
            </Item>
            {!newMessage && (
              <Item xs={'auto'} style={{ marginRight: '1rem' }}>
                <ActionButtonRounded
                  text={'messaging.newDiscussion'}
                  height={2.5}
                  width="auto"
                  fontSize={14}
                  filled
                  onClick={() => newDiscussionChange({ title: '', body: '' })}
                />
              </Item>
            )}
            <Item xs={'auto'}>
              <ActionButtonRounded
                text="general.close"
                height={2.5}
                width="auto"
                fontSize={14}
                filled
                onClick={() => closeMessaging()}
              />
            </Item>
          </Container>
        </div>

        {newMessage ? (
          <NewMessage postNewDiscussion={postNewDiscussion} />
        ) : loadingThreads ? (
          <Container justifyContent={'center'}>
            <Item style={{ paddingTop: '1rem' }}>
              <CircularProgressLocal />
            </Item>
          </Container>
        ) : activePatientThreads.length === 0 ? (
          <Container justifyContent={'center'}>
            <Item style={{ paddingTop: '1rem' }}>
              <h3>{fm('messaging.noDiscussions')}</h3>
            </Item>
          </Container>
        ) : (
          <>
            {activePatientThreads.map((thr) => (
              <div key={thr.id} style={{ marginBottom: '1rem' }}>
                <BlockWrapper
                  collapse={{
                    open: openThreadId === thr.id,
                    collapseHandler: () => {
                      setOpenThreadId(openThreadId === thr.id ? null : thr.id);
                    },
                  }}
                  color="light"
                  title={<ThreadHeader thr={thr} fm={fm} />}
                  contentMargin={1}
                  compact
                >
                  <ThreadOpen thread={thr} />
                </BlockWrapper>
              </div>
            ))}
            {endedPatientThreads.length > 0 && (
              <div>
                <h3 style={{ color: colors.primary, margin: '2rem 0 1rem 0' }}>
                  {fm('messaging.completedDiscussions')}
                </h3>
                {endedPatientThreads.map((ethr) => (
                  <div key={ethr.id} style={{ marginBottom: '1rem' }}>
                    <BlockWrapper
                      collapse={{
                        open: openThreadId === ethr.id,
                        collapseHandler: () => {
                          setOpenThreadId(openThreadId === ethr.id ? null : ethr.id);
                        },
                      }}
                      color="gray"
                      title={<ThreadHeader thr={ethr} fm={fm} />}
                      contentMargin={1}
                      compact
                    >
                      <ThreadOpen thread={ethr} />
                    </BlockWrapper>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </Paper>
    </div>
  );
};

export default MessasingToPatient;
