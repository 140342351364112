import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Container, Item } from 'Components/Grid';
import PlatformSelectVisitReason from './SearchAndCreation/PlatformSelectVisitReason';
import { useAppSelector } from 'Store/index';
import ContentWrapper from 'Components/ContentWrapper';
import TabContent from 'Components/TabContent';
import StatisticsTab from './Statistics';
import SearchAndCreation from './SearchAndCreation';
import { patientSearch } from './fetchers';
import MessageCentral from './MessageCentral';
import { INeuroPersonData } from 'neuro-data-structures';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import Icon from 'Components/_NewElements/Icon';

const MainLander = ({ prevPatient }: IMainLander) => {
  const settings = useAppSelector((s) => s.settings);
  const session = useAppSelector((s) => s.session);
  const { patientid, visitid, patientfirstnames, patientlastnames, patientssn, orgid } = session.data || {};

  const [tabState, setTabState] = React.useState<'patientSearch' | 'extendedSearch' | 'statistics' | 'messaging'>(
    'patientSearch',
  );
  let availableTabs: Record<number, 'patientSearch' | 'extendedSearch' | 'statistics' | 'messaging'> = {
    0: 'patientSearch',
    1: 'extendedSearch',
  };
  const selectTab = (tab: typeof tabState) => {
    setTabState(tab);
    // TODO reset search results?
  };

  const powerBiCapability = settings.orgSettings?.settings?.powerbi;
  const possibleStatisticsPlatforms: Platform[] = [];
  // Find out if and for which platforms a powerbi report id exists
  if (powerBiCapability) {
    const piPlatforms = Object.keys(powerBiCapability) as Platform[];
    piPlatforms.forEach((pp) => {
      if (powerBiCapability[pp]?.reportId) {
        possibleStatisticsPlatforms.push(pp);
      }
    });
    // Add statistics to tabs if available
    if (possibleStatisticsPlatforms.length > 0) availableTabs = { ...availableTabs, 2: 'statistics' };
  }

  // Patient data from "context". Basically data fetched with ssn in case there is no patientid in JWT data
  const [patientFromCtx, setpatientFromCtx] = React.useState<INeuroPersonData | null>(null);
  const [fetchesDone, setFetchesDone] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (prevPatient || fetchesDone) return;

    const fetches: Array<Promise<any>> = [];
    if (orgid && !patientid && patientssn) fetches.push(patientSearch(orgid, patientssn));

    Promise.all(fetches)
      .then((responses) => {
        if (responses[0] && responses[0].length > 0) {
          setpatientFromCtx(responses[0][0]);
        }
      })
      .finally(() => {
        setFetchesDone(true);
      });
  }, []);

  const messageCenterCapa = true;
  if (messageCenterCapa)
    availableTabs = { ...availableTabs, [possibleStatisticsPlatforms.length > 0 ? 3 : 2]: 'messaging' };

  const selectedPatientData = {
    patientid: patientid || patientFromCtx?.id,
    patientfirstnames: patientfirstnames || patientFromCtx?.firstNames,
    patientlastnames: patientlastnames || patientFromCtx?.lastNames,
    patientssn: patientssn,
  };

  return (
    <div style={{ margin: '2rem 0 1rem 0' }}>
      {selectedPatientData.patientid && !visitid ? (
        /*
         * Display visit reason selection if:
         * * There is no visit id and:
         * * The patient id is included in the JWT (session) data or:
         * * Patient search produces a result with the given ssn (from JWT, would imply context login, but some kind of error with data)
         */
        <Container justifyContent="center" style={{ width: '100%', margin: '2.2rem 0' }}>
          <Item>
            <PlatformSelectVisitReason patient={{ ...selectedPatientData, patientid: selectedPatientData.patientid }} />
          </Item>
        </Container>
      ) : (
        /* No patient has been selected at this point, or the page was accessed when some patient page has already been opened */
        <ContentWrapper maxWidth="md">
          <>
            {prevPatient && (
              /* Show back button when opening search page when patient already opened */
              <div style={{ margin: '1rem 0 2rem 0' }}>
                <Link to="/">
                  <ActionButtonRounded
                    width={'auto'}
                    height={3.5}
                    fontSize={15}
                    text={
                      <Container alignItems="center">
                        <Item style={{ marginLeft: '-1rem' }}>
                          <Icon icon="leftMoveArrow" color={'white'} />
                        </Item>
                        <Item>{<FormattedMessage id="general.back" />}</Item>
                      </Container>
                    }
                    filled
                  />
                </Link>
              </div>
            )}
            <TabContent
              indexSelectionTools={{
                index:
                  tabState === 'messaging' ? 3 : tabState === 'statistics' ? 2 : tabState === 'extendedSearch' ? 1 : 0,
                change: (i: number) => selectTab(availableTabs[i]),
              }}
              disableScaling
            >
              {Object.values(availableTabs).map((s, i) => ({
                key: i,
                id: `$tab${i}`,
                title: (
                  <div style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage id={`general.${s}`} />
                  </div>
                ),
                content: (
                  <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
                    {
                      {
                        patientSearch: <SearchAndCreation extended={false} />,
                        extendedSearch: <SearchAndCreation extended={true} />,
                        statistics: <StatisticsTab possibleStatisticsPlatforms={possibleStatisticsPlatforms} />,
                        messaging: <MessageCentral />,
                      }[tabState]
                    }
                  </div>
                ),
                count: i,
              }))}
            </TabContent>
          </>
        </ContentWrapper>
      )}
    </div>
  );
};

interface IMainLander {
  prevPatient?: boolean;
}

export default MainLander;
