import * as React from 'react';
import { styled } from '@mui/system';
import { fm } from 'Components/FormatMessage';
import { isPartialDate, formatPartialDate, exists } from 'neuro-utils';
import colors from '../../../config/theme/colors';

export const StyledHeader = styled('div')({
  marginBottom: '1rem',
  color: colors.primary,
  fontWeight: 600,
  fontSize: '2.2rem',
});

const StyledCollapsedValue = styled('div')({
  color: colors.black,
  fontWeight: 600,
});

/** Content displayed when extended search field is collapsed */
export const CollapsedContent = ({
  value,
  type,
  secondaryValue,
  locPath,
  secondaryLocPath,
}: {
  value: TFieldValue;
  type?: 'numberRange' | 'dateRange' | 'objectArray';
  secondaryValue?: TFieldValue;
  locPath?: string;
  secondaryLocPath?: string;
}): JSX.Element => {
  return (
    <div style={{ overflow: 'auto' }}>
      {[value].concat(secondaryValue ? [secondaryValue] : []).map((val, ind) => {
        let returnVal = undefined;
        const loc = ind === 0 ? locPath : secondaryLocPath;
        switch (type) {
          case 'numberRange':
          case 'dateRange': {
            const valuesFormatted = Object.values(val as { [key: string]: number | PartialDate }).map((v) =>
              isPartialDate(v) ? formatPartialDate(v) : !exists(v) ? fm('extendedSearch.opts.noCropping') : v,
            );
            returnVal = (
              <span>
                {valuesFormatted[0]}
                {' - '}
                {valuesFormatted[1]}
              </span>
            );
            break;
          }
          case 'objectArray': {
            returnVal =
              Array.isArray(val) &&
              (val as [])
                .filter(
                  (v) => typeof v === 'object' && v !== null && Object.keys(v).some((key) => v[key as keyof typeof v]),
                )
                .map((v, i, arr) => (
                  <div key={i} style={{ marginBottom: i < arr.length - 1 ? '1.5rem' : undefined }}>
                    {Object.keys(v).map((key, j) => {
                      const value = v[key as keyof typeof v];
                      const loc = Array.isArray(value) ? secondaryLocPath : locPath;
                      return (
                        <div key={j}>
                          {Array.isArray(value)
                            ? (value as []).map(
                                (w, k, arr) =>
                                  w && (
                                    <React.Fragment key={k}>
                                      {loc ? fm(`${loc}.${w}`) : w}
                                      {k < arr.length - 1 ? <span>{','}&nbsp;</span> : ''}
                                    </React.Fragment>
                                  ),
                              )
                            : value === 'noCropping'
                              ? fm('extendedSearch.opts.noCropping')
                              : loc
                                ? fm(`${loc}.${value}`)
                                : value}
                        </div>
                      );
                    })}
                  </div>
                ));
            break;
          }
          default: {
            if (typeof val === 'string' || typeof val === 'number') {
              if (typeof val === 'string') {
                returnVal =
                  val === 'noCropping' ? fm('extendedSearch.opts.noCropping') : loc ? fm(`${loc}.${val}`) : val;
              } else {
                returnVal = val;
              }
            } else if (typeof val === 'boolean') {
              returnVal = fm(`general.${val}`);
            } else if (Array.isArray(val) && !isPartialDate(val) && val.length > 0) {
              if (val.every((v) => typeof v === 'number')) (val as number[]).sort((a, b) => a - b);
              returnVal = val.map((v, i) => (
                <React.Fragment key={i}>
                  {loc ? fm(`${loc}.${v}`) : v}
                  {i < val.length - 1 ? <span>{','}&nbsp;</span> : ''}
                </React.Fragment>
              ));
            }
          }
        }
        return <StyledCollapsedValue key={'val' + ind}>{returnVal ?? '-'}</StyledCollapsedValue>;
      })}
    </div>
  );
};
